export const VideoSection = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-white to-[#E5DEFF]/20">
      <div className="container">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
          Zobacz nas w akcji
        </h2>
        <div className="max-w-4xl mx-auto aspect-video">
          <iframe
            className="w-full h-full rounded-lg shadow-lg"
            src="https://www.youtube.com/embed/uCFP0P8mzIg"
            title="Występ komediowy dla firm"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <p className="text-center mt-8 text-gray-600 max-w-2xl mx-auto">
          Każdy występ jest dostosowany do potrzeb Twojej firmy i charakteru wydarzenia. 
          Gwarantujemy profesjonalizm i świetną zabawę!
        </p>
      </div>
    </section>
  );
};