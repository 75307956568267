export interface Performance {
  title: string;
  description: string;
  icon: string;
  slug: string;
}

export const performances: Performance[] = [
  {
    title: "Stand-up Comedy",
    description: "Profesjonalni komicy z doświadczeniem w występach firmowych",
    icon: "🎭",
    slug: "stand-up-comedy"
  },
  {
    title: "Kabaret",
    description: "Skecze i scenki komediowe dostosowane do charakteru firmy",
    icon: "🎪",
    slug: "kabaret"
  },
  {
    title: "Iluzjonista",
    description: "Pokazy magii i iluzji z elementami humoru",
    icon: "✨",
    slug: "iluzjonista"
  },
  {
    title: "Teatr Improwizacji",
    description: "Interaktywne przedstawienia tworzone na żywo z udziałem publiczności",
    icon: "🎭",
    slug: "teatr-improwizacji"
  },
  {
    title: "Muzyka na Żywo",
    description: "Profesjonalni muzycy i zespoły różnych gatunków",
    icon: "🎵",
    slug: "muzyka-na-zywo"
  },
  {
    title: "DJ i Wodzirej",
    description: "Oprawa muzyczna i prowadzenie eventów firmowych",
    icon: "🎧",
    slug: "dj-i-wodzirej"
  },
  {
    title: "Pokazy Taneczne",
    description: "Występy profesjonalnych tancerzy różnych stylów",
    icon: "💃",
    slug: "pokazy-taneczne"
  },
  {
    title: "Akrobaci",
    description: "Widowiskowe pokazy akrobatyczne i cyrkowe",
    icon: "🤸",
    slug: "akrobaci"
  },
  {
    title: "Pokazy Świetlne",
    description: "Spektakularne show z wykorzystaniem efektów świetlnych",
    icon: "💡",
    slug: "pokazy-swietlne"
  },
  {
    title: "Mimowie",
    description: "Artyści pantomimy umilający wydarzenie",
    icon: "🎭",
    slug: "mimowie"
  },
  {
    title: "Bubble Show",
    description: "Artystyczne pokazy baniek mydlanych",
    icon: "🫧",
    slug: "bubble-show"
  },
  {
    title: "Fireshow",
    description: "Spektakularne pokazy z ogniem",
    icon: "🔥",
    slug: "fireshow"
  },
  {
    title: "Karykaturzysta",
    description: "Artysta tworzący karykatury gości wydarzenia",
    icon: "🎨",
    slug: "karykaturzysta"
  },
  {
    title: "Pokazy Laserowe",
    description: "Nowoczesne show wykorzystujące technologię laserową",
    icon: "📡",
    slug: "pokazy-laserowe"
  },
  {
    title: "Występy Wokalne",
    description: "Soliści i chóry na wydarzenia firmowe",
    icon: "🎤",
    slug: "wystepy-wokalne"
  },
  {
    title: "Pokazy Barmańskie",
    description: "Widowiskowe pokazy sztuki barmańskiej",
    icon: "🍸",
    slug: "pokazy-barmanskie"
  },
  {
    title: "Teatr Cieni",
    description: "Magiczne przedstawienia wykorzystujące grę światła i cieni",
    icon: "👥",
    slug: "teatr-cieni"
  },
  {
    title: "Pokazy Naukowe",
    description: "Edukacyjne i zabawne eksperymenty naukowe",
    icon: "🔬",
    slug: "pokazy-naukowe"
  },
  {
    title: "Żonglerzy",
    description: "Mistrzowie żonglerki w pokazach artystycznych",
    icon: "🤹",
    slug: "zonglerzy"
  },
  {
    title: "Pokazy Multimedialne",
    description: "Interaktywne show z wykorzystaniem najnowszych technologii",
    icon: "📺",
    slug: "pokazy-multimedialne"
  },
  {
    title: "Występy Operowe",
    description: "Klasyczne wykonania arii operowych",
    icon: "🎭",
    slug: "wystepy-operowe"
  },
  {
    title: "Pokazy Mody",
    description: "Profesjonalne pokazy mody i stylizacji",
    icon: "👗",
    slug: "pokazy-mody"
  },
  {
    title: "Warsztaty Artystyczne",
    description: "Interaktywne zajęcia kreatywne dla uczestników",
    icon: "🎨",
    slug: "warsztaty-artystyczne"
  },
  {
    title: "Pokazy Kulinarne",
    description: "Show cooking z znanymi szefami kuchni",
    icon: "👨‍🍳",
    slug: "pokazy-kulinarne"
  },
  {
    title: "Występy Orkiestrowe",
    description: "Orkiestry symfoniczne i kameralne",
    icon: "🎻",
    slug: "wystepy-orkiestrowe"
  },
  {
    title: "Poetry Slam",
    description: "Dynamiczne występy poetyckie",
    icon: "📚",
    slug: "poetry-slam"
  },
  {
    title: "Pokazy Robotyki",
    description: "Interaktywne prezentacje robotów i AI",
    icon: "🤖",
    slug: "pokazy-robotyki"
  },
  {
    title: "Występy Folklorystyczne",
    description: "Tradycyjne tańce i muzyka ludowa",
    icon: "👥",
    slug: "wystepy-folklorystyczne"
  },
  {
    title: "Pokazy Ekologiczne",
    description: "Edukacyjne show o tematyce ekologicznej",
    icon: "🌱",
    slug: "pokazy-ekologiczne"
  },
  {
    title: "Występy Beatboxerów",
    description: "Mistrzowie vocal percussion w akcji",
    icon: "🎵",
    slug: "wystepy-beatboxerow"
  },
  {
    title: "Występy Jazzowe",
    description: "Eleganckie koncerty jazzowe na wydarzenia firmowe",
    icon: "🎷",
    slug: "wystepy-jazzowe"
  },
  {
    title: "Pokazy Sztuki Cyfrowej",
    description: "Interaktywne instalacje i projekcje multimedialne",
    icon: "💻",
    slug: "pokazy-sztuki-cyfrowej"
  },
  {
    title: "Występy Akordeonowe",
    description: "Tradycyjne i nowoczesne utwory na akordeonie",
    icon: "🪗",
    slug: "wystepy-akordeonowe"
  },
  {
    title: "Pokazy Iluminacji",
    description: "Spektakularne pokazy świetlne i mapping 3D",
    icon: "✨",
    slug: "pokazy-iluminacji"
  },
  {
    title: "Występy Chóralne",
    description: "Profesjonalne chóry na wydarzenia korporacyjne",
    icon: "👥",
    slug: "wystepy-choralne"
  },
  {
    title: "Pokazy Bodypainting",
    description: "Artystyczne malowanie ciała na żywo",
    icon: "🎨",
    slug: "pokazy-bodypainting"
  },
  {
    title: "Występy Perkusyjne",
    description: "Energetyczne show perkusyjne i drumline",
    icon: "🥁",
    slug: "wystepy-perkusyjne"
  },
  {
    title: "Pokazy Pirotechniczne",
    description: "Bezpieczne pokazy pirotechniczne indoor i outdoor",
    icon: "💥",
    slug: "pokazy-pirotechniczne"
  },
  {
    title: "Występy Skrzypcowe",
    description: "Klasyczne i współczesne wykonania na skrzypcach",
    icon: "🎻",
    slug: "wystepy-skrzypcowe"
  },
  {
    title: "Pokazy Sztuki Ulicznej",
    description: "Profesjonalni artyści street art w akcji",
    icon: "🎨",
    slug: "pokazy-sztuki-ulicznej"
  },
  {
    title: "Występy Gitarowe",
    description: "Wirtuozi gitary klasycznej i elektrycznej",
    icon: "🎸",
    slug: "wystepy-gitarowe"
  },
  {
    title: "Pokazy Origami",
    description: "Mistrzowie sztuki składania papieru",
    icon: "📄",
    slug: "pokazy-origami"
  },
  {
    title: "Występy Harfowe",
    description: "Eleganckie koncerty na harfie",
    icon: "🎼",
    slug: "wystepy-harfowe"
  },
  {
    title: "Pokazy Garncarskie",
    description: "Tworzenie ceramiki na żywo",
    icon: "🏺",
    slug: "pokazy-garncarskie"
  },
  {
    title: "Występy Fletowe",
    description: "Muzyka na flecie poprzecznym i fletni Pana",
    icon: "🎵",
    slug: "wystepy-fletowe"
  },
  {
    title: "Pokazy Rzeźbiarskie",
    description: "Tworzenie rzeźb na żywo podczas wydarzenia",
    icon: "🗿",
    slug: "pokazy-rzezbiarskie"
  },
  {
    title: "Występy Saksofonu",
    description: "Smooth jazz i muzyka rozrywkowa na saksofonie",
    icon: "🎷",
    slug: "wystepy-saksofonu"
  },
  {
    title: "Pokazy Kaligrafii",
    description: "Artyści kaligrafii w akcji",
    icon: "✒️",
    slug: "pokazy-kaligrafii"
  },
  {
    title: "Występy Trąbki",
    description: "Energetyczne show na trąbce",
    icon: "🎺",
    slug: "wystepy-trabki"
  },
  {
    title: "Pokazy Sztuczek Karcianych",
    description: "Profesjonalni karciarze i ich triki",
    icon: "🃏",
    slug: "pokazy-sztuczek-karcianych"
  },
  {
    title: "Występy Puzonu",
    description: "Muzyka jazzowa i klasyczna na puzonie",
    icon: "🎵",
    slug: "wystepy-puzonu"
  },
  {
    title: "Pokazy Żonglerki Ogniem",
    description: "Spektakularne pokazy z wykorzystaniem ognia",
    icon: "🔥",
    slug: "pokazy-zonglerki-ogniem"
  },
  {
    title: "Występy Wiolonczeli",
    description: "Klasyczne i współczesne utwory na wiolonczeli",
    icon: "🎻",
    slug: "wystepy-wiolonczele"
  },
  {
    title: "Pokazy Sztuki Kinetycznej",
    description: "Interaktywne instalacje artystyczne w ruchu",
    icon: "🎭",
    slug: "pokazy-sztuki-kinetycznej"
  },
  {
    title: "Występy Kontrabasu",
    description: "Jazz i muzyka klasyczna na kontrabasie",
    icon: "🎵",
    slug: "wystepy-kontrabasu"
  },
  {
    title: "Pokazy Sztuki Światła",
    description: "Artystyczne instalacje świetlne",
    icon: "💡",
    slug: "pokazy-sztuki-swiatla"
  },
  {
    title: "Występy Ukulele",
    description: "Hawajskie brzmienia na firmowym wydarzeniu",
    icon: "🎸",
    slug: "wystepy-ukulele"
  },
  {
    title: "Pokazy Sztuki Papierowej",
    description: "Tworzenie dzieł sztuki z papieru",
    icon: "📜",
    slug: "pokazy-sztuki-papierowej"
  },
  {
    title: "Występy Mandoliny",
    description: "Tradycyjne i współczesne utwory na mandolinie",
    icon: "🎵",
    slug: "wystepy-mandoliny"
  },
  {
    title: "Pokazy Sztuki Recyklingu",
    description: "Tworzenie sztuki z materiałów z odzysku",
    icon: "♻️",
    slug: "pokazy-sztuki-recyklingu"
  }
];
