import { Star } from "lucide-react";

const testimonials = [
  {
    name: "Anna Kowalska",
    company: "Tech Solutions Sp. z o.o.",
    text: "Świetny występ! Nasi pracownicy byli zachwyceni, a pozytywna energia utrzymywała się jeszcze przez wiele dni.",
  },
  {
    name: "Marek Nowak",
    company: "Bank Regionalny",
    text: "Profesjonalne podejście i świetne dostosowanie żartów do naszej branży. Polecam!",
  },
];

export const Testimonials = () => {
  return (
    <section className="py-20 bg-primary text-white">
      <div className="container">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12">
          Co mówią nasi klienci?
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {testimonials.map((testimonial) => (
            <div key={testimonial.name} className="p-6 rounded-lg bg-white/10 backdrop-blur">
              <div className="flex mb-4">
                {[1, 2, 3, 4, 5].map((i) => (
                  <Star key={i} className="w-5 h-5 text-accent" fill="currentColor" />
                ))}
              </div>
              <p className="font-inter mb-4">{testimonial.text}</p>
              <div>
                <p className="font-montserrat font-bold">{testimonial.name}</p>
                <p className="text-sm text-gray-300">{testimonial.company}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};