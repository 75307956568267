import axios from "axios";
import { useEffect, useState } from "react";

const GlobalFooter = () => {
    const [footer, setFooter] = useState<{ top: string, bottom: string }>({ top: '', bottom: '' });

    useEffect(() => {
        const source = axios.CancelToken.source();

        async function fetchData() {
            try {
                const res = await axios({
                    method: 'get',
                    url: 'https://footer.aigency.pl/api/footer',
                    cancelToken: source.token
                });
                setFooter(res.data);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error fetching footer data:', error);
                }
            }
        }

        fetchData();

        return () => {
            source.cancel();
        }
    }, []);

    return (
        <div className="text-center text-gray-400 space-y-2 mt-4 text-sm">
            <p dangerouslySetInnerHTML={{ __html: footer.top.replace('XXXXXXXX', '2024') }}></p>
            <p dangerouslySetInnerHTML={{ __html: footer.bottom }}></p>
        </div>
    );
};

export default GlobalFooter;