import { Hero } from "@/components/Hero";
import { About } from "@/components/About";
import { Offer } from "@/components/Offer";
import { Benefits } from "@/components/Benefits";
import { WhyUs } from "@/components/WhyUs";
import { FormsOfComedy } from "@/components/FormsOfComedy";
import { Testimonials } from "@/components/Testimonials";
import { ContactForm } from "@/components/ContactForm";
import { Navigation } from "@/components/Navigation";
import { Footer } from "@/components/Footer";
import { VideoSection } from "@/components/VideoSection";
import { Separator } from "@/components/Separator";

const Index = () => {
  return (
    <div className="min-h-screen pt-16">
      <Navigation />
      <Hero />
      <Separator />
      <About />
      <Separator />
      <Offer />
      <Separator />
      <Benefits />
      <Separator />
      <WhyUs />
      <Separator />
      <FormsOfComedy />
      <Separator />
      <VideoSection />
      <Separator />
      <Testimonials />
      <Separator />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Index;