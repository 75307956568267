import { Link } from "react-router-dom";
import { Performance } from "@/data/performances";

interface OfferCardProps {
  performance: Performance;
}

export const OfferCard = ({ performance }: OfferCardProps) => {
  return (
    <Link 
      to={`/atrakcje/${performance.slug}`}
      className="block h-full"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 h-[200px] flex flex-col justify-between">
        <div className="text-4xl mb-4 text-center">
          {performance.icon}
        </div>
        <div>
          <h3 className="text-xl font-montserrat font-bold mb-2 text-primary text-center">
            {performance.title}
          </h3>
          <p className="text-gray-600 text-center text-sm line-clamp-2">
            {performance.description}
          </p>
        </div>
      </div>
    </Link>
  );
};