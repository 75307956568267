import { Button } from "@/components/ui/button";
import { Phone } from "lucide-react";
import { useState, useEffect } from "react";

export const Hero = () => {
  const [showPhone, setShowPhone] = useState(false);
  const [currentText, setCurrentText] = useState(0);
  const texts = ["Firmowych", "Prywatnych", "Specjalnych"];

  useEffect(() => {
    const phoneInterval = setInterval(() => {
      setShowPhone(prev => !prev);
    }, 3000);

    const textInterval = setInterval(() => {
      setCurrentText(prev => (prev + 1) % texts.length);
    }, 4000);

    return () => {
      clearInterval(phoneInterval);
      clearInterval(textInterval);
    };
  }, []);

  const scrollToContactForm = () => {
    const contactForm = document.getElementById('kontakt');
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative min-h-[80vh] flex items-center justify-center bg-gradient-to-br from-primary to-[#9B8BC4] text-white overflow-hidden">
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-10 left-1/4 transform -rotate-12">
          <span className="text-8xl">🎭</span>
        </div>
        <div className="absolute top-1/3 right-1/4 transform rotate-12">
          <span className="text-8xl">🎪</span>
        </div>
        <div className="absolute bottom-1/4 left-1/3 transform -rotate-6">
          <span className="text-8xl">🎨</span>
        </div>
      </div>
      
      <div 
        className="absolute inset-0 opacity-20 bg-cover bg-center mix-blend-overlay"
        style={{ backgroundImage: "url('/performance-bg.jpg')" }}
      />
      
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-64 h-64 bg-accent/20 rounded-full -top-20 -left-20 blur-xl animate-pulse" />
        <div className="absolute w-96 h-96 bg-secondary/10 rounded-full -bottom-32 -right-32 blur-xl animate-pulse delay-1000" />
      </div>

      <div className="container relative z-10 text-center px-4">
        <h1 className="font-montserrat text-5xl md:text-7xl font-bold mb-6 animate-fade-in">
          Występy dla wydarzeń<br />
          <span className="bg-gradient-to-r from-accent to-secondary text-transparent bg-clip-text transition-all duration-500">
            {texts[currentText]}!
          </span>
        </h1>
        <div className="font-inter text-xl md:text-2xl mb-8 max-w-3xl mx-auto space-y-2">
          <p className="text-gray-200">
            Profesjonalne występy artystyczne dla firm i osób prywatnych,
          </p>
          <p className="text-gray-200">
            które sprawią, że Twoje wydarzenie będzie niezapomniane!
          </p>
        </div>
        <div className="flex justify-center">
          <Button 
            size="lg" 
            onClick={scrollToContactForm}
            className="bg-accent text-primary hover:bg-accent/90 text-lg w-[220px] flex items-center justify-center gap-2 transform hover:scale-105 transition-all duration-300"
          >
            {showPhone ? (
              <>
                <Phone className="w-5 h-5" />
                +48 533 333 993
              </>
            ) : (
              "Zamów występ"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};