import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Index from "./pages/Index";
import { performances } from "./data/performances";
import LoadingSpinner from "./components/LoadingSpinner";

const queryClient = new QueryClient();

// Lazy load attraction pages
const AttractionPage = lazy(() => import("./pages/attractions/[slug]"));

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Toaster />
      <Sonner />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          {performances.map((performance) => (
            <Route 
              key={performance.slug}
              path={`/atrakcje/${performance.slug}`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <AttractionPage slug={performance.slug} />
                </Suspense>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;