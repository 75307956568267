import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import axios from "axios";

export const ContactForm = () => {
  const { toast } = useToast();
  const [eventType, setEventType] = useState("firmowe");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    try {
      await axios.post(`${import.meta.env.VITE_API_URL}/api/form`, {
        website: 'www.wystep.pl',
        name: formData.get('name'),
        email: formData.get('email'),
        phone: formData.get('phone'),
        eventType: eventType,
        message: formData.get('message')
      });

      toast({
        title: "Formularz wysłany!",
        description: "Skontaktujemy się z Tobą najszybciej jak to możliwe.",
      });
      form.reset();
    } catch (error: any) {
      if (error.response?.status === 422) {
        toast({
          title: "Błąd walidacji",
          description: error.response.data.message || "Sprawdź poprawność wprowadzonych danych",
          variant: "destructive",
        });
      } else {
        toast({
          title: "Wystąpił błąd",
          description: "Przepraszamy, spróbuj ponownie później",
          variant: "destructive",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="py-20 bg-gradient-to-br from-[#F2FCE2]/30 to-[#D3E4FD]/20" id="kontakt">
      <div className="container max-w-2xl">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
          Zamów występ
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6 bg-white/90 backdrop-blur-sm p-8 rounded-lg shadow-lg">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="font-inter mb-2 block">Imię i nazwisko</label>
              <Input name="name" required />
            </div>
            <div>
              <label className="font-inter mb-2 block">Email</label>
              <Input name="email" type="email" required />
            </div>
          </div>
          
          <div>
            <label className="font-inter mb-2 block">Numer telefonu</label>
            <Input name="phone" type="tel" required />
          </div>

          <div className="space-y-3">
            <label className="font-inter block">Rodzaj wydarzenia</label>
            <RadioGroup defaultValue="firmowe" onValueChange={setEventType}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="firmowe" id="firmowe" />
                <Label htmlFor="firmowe">Wydarzenie firmowe</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="prywatne" id="prywatne" />
                <Label htmlFor="prywatne">Impreza prywatna</Label>
              </div>
            </RadioGroup>
          </div>

          <div>
            <label className="font-inter mb-2 block">Wiadomość</label>
            <Textarea name="message" required className="min-h-[150px]" />
          </div>

          <Button 
            type="submit" 
            className="w-full bg-[#8B5CF6] hover:bg-[#7C3AED] text-white transition-colors"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Wysyłanie..." : "Wyślij zapytanie"}
          </Button>
        </form>
      </div>
    </section>
  );
};