import { Button } from "@/components/ui/button";
import { Phone } from "lucide-react";
import { useState, useEffect } from "react";

export const Navigation = () => {
  const [showPhone, setShowPhone] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowPhone(prev => !prev);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-primary/95 backdrop-blur-sm z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <a href="/" className="text-2xl font-montserrat font-bold text-white">
          Wystep.pl
        </a>
        <div className="hidden md:flex gap-6 items-center">
          <button onClick={() => scrollToSection("about")} className="text-white hover:text-accent">
            O nas
          </button>
          <button onClick={() => scrollToSection("offer")} className="text-white hover:text-accent">
            Oferta
          </button>
          <button onClick={() => scrollToSection("benefits")} className="text-white hover:text-accent">
            Dlaczego warto?
          </button>
          <button onClick={() => scrollToSection("why-us")} className="text-white hover:text-accent">
            Dlaczego my?
          </button>
          <button onClick={() => scrollToSection("kontakt")} className="text-white hover:text-accent">
            Kontakt
          </button>
          <Button 
            onClick={() => window.location.href = "tel:+48533333993"}
            className="bg-accent text-primary hover:bg-accent/90 w-[180px] transition-all duration-300 flex items-center justify-center gap-2"
          >
            {showPhone ? (
              <>
                <Phone className="w-4 h-4" />
                +48 533 333 993
              </>
            ) : (
              "Zamów występ"
            )}
          </Button>
        </div>
      </div>
    </nav>
  );
};