import { Mic, Theater, Lightbulb, Users, Flame, Music, Wand2, Copy, VenetianMask } from "lucide-react";

const comedyForms = [
  {
    icon: Mic,
    title: "Stand-up",
    description: "Klasyczna forma komedii, gdzie komik występuje sam na scenie, dzieląc się swoimi obserwacjami i historiami."
  },
  {
    icon: Theater,
    title: "Kabaret",
    description: "Skecze i scenki komediowe w wykonaniu grupy artystów, często z wykorzystaniem rekwizytów i kostiumów."
  },
  {
    icon: Lightbulb,
    title: "Improwizacja",
    description: "Spontaniczne tworzenie scenek i historii na podstawie sugestii publiczności."
  },
  {
    icon: Users,
    title: "Komedia improwizowana",
    description: "Dłuższe formy teatralne tworzone na żywo, bez scenariusza, z aktywnym udziałem widowni."
  },
  {
    icon: Flame,
    title: "Roast",
    description: "Humorystyczne przedstawienie, gdzie zaproszeni goście żartobliwie krytykują głównego bohatera wydarzenia."
  },
  {
    icon: Copy,
    title: "Parodia",
    description: "Komiczne naśladowanie znanych osób, utworów lub zjawisk w zabawny i przejaskrawiony sposób."
  },
  {
    icon: Music,
    title: "Karaoke komediowe",
    description: "Połączenie śpiewu z elementami komedii, gdzie uczestnicy wykonują zabawne wersje znanych utworów."
  },
  {
    icon: Wand2,
    title: "Magik komediowy",
    description: "Występy łączące sztukę iluzji z elementami komedii i humoru."
  },
  {
    icon: VenetianMask,
    title: "Pantomima komediowa",
    description: "Komediowe przedstawienie bez użycia słów, oparte na gestach i mimice."
  }
];

export const FormsOfComedy = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-[#D3E4FD]/30 to-[#F2FCE2]/30">
      <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
        Formy komedii
      </h2>
      <div className="grid md:grid-cols-3 gap-8">
        {comedyForms.map((form) => {
          const Icon = form.icon;
          return (
            <div 
              key={form.title} 
              className="p-6 rounded-lg bg-gradient-to-br from-white to-[#FFDEE2]/20 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-center justify-center mb-4">
                <Icon className="w-12 h-12 text-[#8B5CF6]" />
              </div>
              <h3 className="text-xl font-montserrat font-bold mb-2 text-center text-[#D946EF]">
                {form.title}
              </h3>
              <p className="text-gray-600 text-center">{form.description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};