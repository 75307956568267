import { Star, Award, Users, Clock, Shield, ThumbsUp } from "lucide-react";

const reasons = [
  {
    icon: Star,
    title: "Doświadczenie",
    description: "Ponad 500 występów dla największych firm w Polsce",
  },
  {
    icon: Award,
    title: "Profesjonalizm",
    description: "Zawodowi komicy z wieloletnim stażem scenicznym",
  },
  {
    icon: Users,
    title: "Dostosowanie",
    description: "Program dopasowany do charakteru i potrzeb Twojej firmy",
  },
  {
    icon: Clock,
    title: "Punktualność",
    description: "Zawsze na czas, zawsze przygotowani",
  },
  {
    icon: Shield,
    title: "Gwarancja",
    description: "Gwarantujemy zadowolenie lub zwrot pieniędzy",
  },
  {
    icon: ThumbsUp,
    title: "Rekomendacje",
    description: "Setki pozytywnych opinii od zadowolonych klientów",
  },
];

export const WhyUs = () => {
  return (
    <section id="why-us" className="py-20 bg-[#FEF7CD]/20">
      <div className="container">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
          Dlaczego my?
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {reasons.map((reason) => {
            const Icon = reason.icon;
            return (
              <div key={reason.title} className="p-6 rounded-lg bg-white shadow-lg hover:shadow-xl transition-shadow">
                <Icon className="w-12 h-12 text-primary mb-4" />
                <h3 className="text-xl font-montserrat font-bold mb-2">{reason.title}</h3>
                <p className="text-gray-600 font-inter">{reason.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};