export const About = () => {
  return (
    <section id="about" className="py-20 bg-gradient-to-br from-white to-secondary/30">
      <div className="container">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
          O nas
        </h2>
        <div className="max-w-4xl mx-auto text-center bg-white/80 backdrop-blur-sm p-8 rounded-lg shadow-lg">
          <p className="text-lg mb-6 text-gray-700">
            Jesteśmy zespołem profesjonalnych artystów z wieloletnim doświadczeniem scenicznym. 
            Specjalizujemy się w występach artystycznych dla firm i osób prywatnych, które 
            uświetniają każde wydarzenie i pozostają w pamięci na długo.
          </p>
          <p className="text-lg mb-6 text-gray-700">
            Nasze występy są zawsze dopasowane do charakteru wydarzenia i potrzeb klienta. 
            Łączymy profesjonalizm z kreatywnością, tworząc niezapomniane show, które 
            zachwyca każdą publiczność.
          </p>
          <p className="text-lg text-gray-700">
            Działamy na terenie całej Polski, zapewniając najwyższą jakość usług i gwarancję 
            zadowolenia klientów.
          </p>
        </div>
      </div>
    </section>
  );
};