import { Facebook, Instagram, Linkedin } from "lucide-react";
import GlobalFooter from "./GlobalFooter";

export const Footer = () => {
  return (
    <footer className="bg-primary text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8 text-center">
          <div>
            <h3 className="font-montserrat font-bold text-xl mb-4">Wystep.pl</h3>
            <p className="text-gray-300">
              Profesjonalne występy artystyczne dla firm,<br />
              które uświetniają każde wydarzenie.
            </p>
          </div>
          <div>
            <h4 className="font-montserrat font-bold text-lg mb-4">Kontakt</h4>
            <p className="text-gray-300">
              Email: <a href="mailto:kontakt@wystep.pl" className="hover:text-accent">kontakt@wystep.pl</a>
            </p>
            <p className="text-gray-300">Tel: +48 533 333 993</p>
          </div>
          <div>
            <h4 className="font-montserrat font-bold text-lg mb-4">Social Media</h4>
            <div className="flex gap-4 justify-center">
              <a href="#" className="text-gray-300 hover:text-accent">
                <Facebook size={24} />
              </a>
              <a href="#" className="text-gray-300 hover:text-accent">
                <Instagram size={24} />
              </a>
              <a href="#" className="text-gray-300 hover:text-accent">
                <Linkedin size={24} />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700">
          <GlobalFooter />
        </div>
      </div>
    </footer>
  );
};