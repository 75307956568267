import { performances } from "@/data/performances";
import { OfferCard } from "./OfferCard";

export const Offer = () => {
  return (
    <section id="offer" className="py-20 bg-gradient-to-br from-[#F2FCE2]/50 to-[#accbee]/30">
      <div className="container">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
          Nasza Oferta
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {performances.map((performance) => (
            <OfferCard key={performance.title} performance={performance} />
          ))}
        </div>
      </div>
    </section>
  );
};