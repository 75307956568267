import { CheckCircle, Laugh, Users, Brain, Building2, MessageSquare, Star } from "lucide-react";

const benefits = [
  {
    title: "Integracja zespołu",
    description: "Wspólny śmiech zbliża ludzi i buduje silniejsze relacje w zespole",
    icon: Users
  },
  {
    title: "Redukcja stresu",
    description: "Humor to najlepszy sposób na rozładowanie napięcia w pracy",
    icon: Brain
  },
  {
    title: "Pozytywny wizerunek",
    description: "Pokazujesz, że Twoja firma dba o dobrą atmosferę i zadowolenie pracowników",
    icon: Building2
  },
  {
    title: "Wzrost produktywności",
    description: "Zrelaksowani pracownicy są bardziej efektywni i kreatywni",
    icon: Star
  },
  {
    title: "Lepsza komunikacja",
    description: "Humor pomaga przełamać bariery i ułatwia komunikację w zespole",
    icon: MessageSquare
  },
  {
    title: "Niezapomniane wspomnienia",
    description: "Tworzymy momenty, które na długo pozostaną w pamięci pracowników",
    icon: Laugh
  },
];

export const Benefits = () => {
  return (
    <section id="benefits" className="py-20 bg-gradient-to-br from-white to-[#F2FCE2]/30">
      <div className="container">
        <h2 className="text-4xl font-montserrat font-bold text-center mb-12 text-primary">
          Dlaczego warto?
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {benefits.map((benefit) => {
            const Icon = benefit.icon;
            return (
              <div 
                key={benefit.title} 
                className="p-6 rounded-lg border border-gray-100 hover:shadow-lg transition-all duration-300 hover:-translate-y-1 bg-gradient-to-br from-white to-[#FDE1D3]/30 backdrop-blur-sm"
              >
                <div className="flex items-center justify-center mb-4">
                  <Icon className="w-12 h-12 text-[#F97316]" />
                </div>
                <h3 className="text-xl font-montserrat font-bold mb-2 text-center text-[#403E43]">{benefit.title}</h3>
                <p className="text-gray-600 font-inter text-center">{benefit.description}</p>
              </div>
            )})}
        </div>
      </div>
    </section>
  );
};